import React from 'react'
import styled from 'styled-components'

const Wrapper = ({ children, className }) => (
  <Container className={className}>
    {children}
  </Container>
)

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`

export default Wrapper
