import React from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
// import logo from 'images/favicon.png'

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "favicon.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <LogoLink to='/'>
      <Img
        fixed={data.file.childImageSharp.fixed}
        alt='Home | queroser.ninja'
      />
      <H1>queroser.ninja</H1>
    </LogoLink>
  )
}

const Img = styled(Image)`
  transition: all 0.15s ease-in-out;
  transform: rotate(0deg);
`

const H1 = styled.h1`
  color: #333;
  font-size: 18px;
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  width: 0;
`

const LogoLink = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;

  ${Img} {
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    outline: 0;

    @media all and (min-width: 380px) {
      & ${H1} {
        width: 150px;
      }
    }

    & ${Img} {
      transform: rotate(95deg);
    }
  }
`

export default Logo
