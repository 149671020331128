import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import RawWrapper from './wrapper'
import Logo from './logo'

function Header () {
  return (
    <Container>
      <Wrapper>
        <Logo />

        <PromotionsLink>
          Promoções
        </PromotionsLink>
      </Wrapper>
    </Container>
  )
}

const Container = styled.header`
  align-items: center;
  background: #ecf0f1;
  display: flex;
`

const Wrapper = styled(RawWrapper)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
`

const PromotionsLink = styled(Link).attrs({
  to: '/promocoes'
})`
  align-items: center;
  background: #A82315;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  padding: 0 12px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.15s ease-in-out;

  &:hover {
    background: #c0392b;
  }
`

export default Header
